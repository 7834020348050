import axios from "axios";
import { Api } from "./consts";
import { getCookie } from "./helper";
import objectToURLSearchParams from "./objectToURLSearchParams";
let access_token = getCookie("access_token");

const APIData = axios.create({
  baseURL: Api,
});

export default class API {
  static login(parameters = {}) {
    return APIData.post(`login`, {
      email: parameters.username,
      password: parameters.password,
      remember: parameters.rememberIsChecked,
    });
  }

  static getAuthUser(parameters = {}) {
    return APIData.get(`profile`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static logout(parameters = {}) {
    return APIData.post(
      `logout`,
      {},
      { headers: { Authorization: `Bearer  ${access_token}` } }
    );
  }

  static getRequestProjectTasks(parameters = {}) {
    return APIData.get(
      `task/${parameters.project}/requests-kanban?type=${
        parameters.record_type ?? ""
      }&view=${parameters.view}${
        parameters.page ? "&page=" + parameters.page : ""
      }${parameters.board ? "&board=" + parameters.board : ""}${
        parameters.company_id ? "&company_id=" + parameters.company_id : ""
      }${parameters.coder_id ? "&coder_id=" + parameters.coder_id : ""}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
  }

  static getUserTasks(parameters = {}) {
    return APIData.get(
      `task/${parameters.project}/my-requests-kanban?type=${
        parameters.record_type ?? ""
      }&view=${parameters.view}${
        parameters.page ? "&page=" + parameters.page : ""
      }${parameters.board ? "&board=" + parameters.board : ""}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
  }

  static getTasksForTimeline(parameters = 0) {
    return APIData.get(`task/${parameters}/timeline`, {
      // for tests
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static updateTaskStatus(parameters = {}) {
    return APIData.post(
      `task/update/status`,
      {
        task_id: parameters.task_id,
        source: parameters.source,
        destination: parameters.destination,
        emailsToSend: parameters.emailsToSend
          ? parameters.emailsToSend.emails
          : null,
        ...(parameters?.emailsToSend?.url && {
          work_url: parameters?.emailsToSend?.url,
        }),
        ...(parameters?.emailsToSend?.report_note && {
          report_text: parameters?.emailsToSend?.report_note,
        }),
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static addTask(parameters = {}) {
    return APIData.post(
      `task`,
      {
        // pricing_id: 1,
        kanban: parameters.isKanban,
        type: parameters.type,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static addSubTask(parameters = {}) {
    return APIData.post(
      `task/subtask/new`,
      {
        parent_id: parameters,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static updateTask(parameters = {}) {
    return APIData.post(
      `task/update`,
      {
        task_id: parameters.task_id,
        title: parameters.title,
        description: parameters.description,
        clarified_description_to_client:
          parameters.clarified_description_to_client,
        // actual_time_used: parameters.actual_time_used,
        // estimated_time: parameters.estimated_time,
        credit_used: parameters.credit_used,
        due_date: parameters.due_date,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static updateTimeUsed(parameters = {}) {
    return APIData.post(
      `task/update/actual-time-used`,
      {
        task_id: parameters.task_id,
        time: parameters.time,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static updateEstimateUsed(parameters = {}) {
    return APIData.post(
      `task/update/estimated-time`,
      {
        task_id: parameters.task_id,
        time: parameters.time,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static updateBillableTime(parameters = {}) {
    return APIData.post(
      `task/update/billable-time`,
      {
        task_id: parameters.task_id,
        time: parameters.time,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static updateTimeAdjustments(parameters = {}) {
    return APIData.post(
      `task/update/time-adjustments`,
      {
        task_id: parameters.task_id,
        time_adjustments: parameters.timeAdjustments,
        is_add: parameters.is_add,
        note: parameters.note,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static requestorUpdate(parameters = {}) {
    return APIData.post(
      `task/update/requestor`,
      {
        task_id: parameters.task_id,
        requestor_id: parameters.requestor_id,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static pricingUpdate(parameters = {}) {
    return APIData.post(
      `task/update/pricing-method`,
      {
        task_id: parameters.task_id,
        pricing_method: parameters.pricing_id,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static adminAssigneeUpdate(parameters = {}) {
    return APIData.post(
      `task/update/admin-assignee`,
      {
        task_id: parameters.task_id,
        admin_assignee: parameters.admin_assignee,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static removeAdminAssignee(parameters = {}) {
    return APIData.post(
      `task/remove/admin-assignee`,
      {
        task_id: parameters.task_id,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static primaryUpdate(parameters = {}) {
    return APIData.post(
      `task/update/primary-assignee`,
      {
        task_id: parameters.task_id,
        primary_assignee: parameters.primary_assignee,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static removePrimary(parameters = {}) {
    return APIData.post(
      `task/remove/primary-assignee`,
      {
        task_id: parameters.task_id,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static qaUpdate(parameters = {}) {
    return APIData.post(
      `task/update/qa-assignee`,
      {
        task_id: parameters.task_id,
        qa_assignee: parameters.qa_assignee,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static removeQA(parameters = {}) {
    return APIData.post(
      `task/remove/qa-assignee`,
      {
        task_id: parameters.task_id,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static statusUpdate(parameters = {}) {
    return APIData.post(
      `task/update/status`,
      {
        task_id: parameters.task_id,
        status_id: parameters.status,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static requestTypeUpdate(parameters = {}) {
    return APIData.post(
      `task/update/request-type`,
      {
        task_id: parameters.task_id,
        request_type_id: parameters.request_type_id,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static deleteRequest(parameters = {}) {
    return APIData.post(
      `task/delete`,
      {
        task_id: parameters.task_id,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static updateProperty(parameters = {}) {
    return APIData.post(
      `task/update/property-id`,
      {
        task_id: parameters.task_id,
        property_id: parameters.property_id,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static saveComment(parameters = {}) {
    return APIData({
      method: "post",
      url: "task/comment",
      data: parameters,
      headers: {
        Authorization: `Bearer  ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static deleteComment(parameters = {}) {
    return APIData.post(
      `email/delete`,
      {
        id: parameters,
      },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
  }

  static updateAttachment(parameters = {}) {
    return APIData({
      method: "post",
      url: "task/update/attachments",
      data: parameters,
      headers: {
        Authorization: `Bearer  ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static dueUpdate(parameters = {}) {
    return APIData.post(
      `task/update/due-date`,
      {
        task_id: parameters.task_id,
        due_date: parameters.dueDate,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static test(parameters = {}) {
    return APIData.post(
      `test`,
      {
        test: "test param",
      },
      {
        headers: {
          Authorization: `Bearer  ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  static doneUpdate(parameters = {}) {
    return APIData.post(
      `task/update/mark-done`,
      {
        task_id: parameters.task_id,
        is_done: parameters.is_done,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static taskDetails(parameters = 0) {
    return APIData.get(`task/${parameters}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getTaskActivities(id = 0) {
    return APIData.get(`task-activities/${id}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getTaskReports(id = 0) {
    return APIData.get(`task-reports/${id}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getContacts() {
    return APIData.get(`contacts`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getContactsProjects(id) {
    return APIData.get(`contact/${id}/projects`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getCompany() {
    return APIData.get(`company`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getCompanies() {
    return APIData.get(`company`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getCompanyContacts(id) {
    return APIData.get(`company/${id}/contact-options`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static saveUser(parameters = {}) {
    return APIData.post(
      `user`,
      {
        firstname: parameters.firstname,
        surname: parameters.surname,
        email: parameters.email,
        username: "",
        password: "",
        decision_maker: 0,
        company_id: parameters.company_id,
        position: parameters.position,
        userType: parameters.userType,
        mobile: parameters.mobile,
        phone: parameters.phone,
        address: parameters.address,
        city: parameters.city,
        state: parameters.state,
        country: parameters.country,
        role: "user",
        userGroupId: parameters.userGroupId,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static updateUser(parameters = {}) {
    return APIData.post(
      `user/update`,
      {
        id: parameters.id,
        firstname: parameters.firstname,
        surname: parameters.surname,
        email: parameters.email,
        username: "",
        password: "",
        decision_maker: 0,
        company_id: parameters.company_id,
        position: parameters.position,
        userType: parameters.userType,
        mobile: parameters.mobile,
        phone: parameters.phone,
        address: parameters.address,
        city: parameters.city,
        state: parameters.state,
        country: parameters.country,
        role: "user",
        userGroupId: parameters.userGroupId,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static userProfile(parameters) {
    return APIData.get(`user/${parameters}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getPropertyInfo(parameters) {
    return APIData.get(`property/${parameters}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getProperties(propertyType, search) {
    let uri = propertyType;
    if (search === "" || search === undefined) {
      search = null;
    }
    if (search) {
      uri = `${uri}/${search}`;
    }
    return APIData.get(uri, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getAllProperties() {
    return APIData.get("property", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static updateUserStatus(parameters) {
    return APIData.post(
      `user/update/status`,
      {
        id: parameters.id,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static companyProfile(parameters) {
    return APIData.get(`company/${parameters}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static allTask(parameters) {
    return APIData.get(`task`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static allCoders() {
    return APIData.get(`coders`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static allUser() {
    return APIData.get(`user`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getAdminAndCoders() {
    return APIData.get(`admin-coder-options`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static addTaskContact(parameters = {}) {
    return APIData.post(
      `task`,
      {
        project_id: parameters.project_id,
        title: parameters.title,
        description: parameters.details,
        assignee_id: parameters.assignee,
        qa_id: parameters.qa,
        due_date: parameters.due_date,
        type: parameters.type,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static addTaskCompany(parameters = {}) {
    console.log(parameters);
    return APIData.post(
      `task`,
      {
        company_id: parameters.company_id,
        project_id: parameters.project_id,
        title: parameters.title,
        clarified_description: parameters.clarified_description,
        description: parameters.description,
        assignee_id: parameters.assignee_id,
        qa_id: parameters.qa_id,
        due_date: parameters.due_date,
        type: parameters.type,
        contact_id: parameters.contact_id,
        cc_ids: parameters.cc_ids,
        email_id: parameters.email_id,
        requestor_id: parameters.requestor,
        requestor_email: parameters.requestor_email,
        estimated_time: parameters.estimateTime,
        property_id: parameters.property_id,
        pricing_id: parameters.pricing_id,
      },
      {
        headers: { Authorization: `Bearer  ${access_token}` },
      }
    );
  }

  static saveCompany(parameters = {}) {
    return APIData.post(
      `company`,
      {
        name: parameters.name,
        organisation: parameters.organisation,
        abn: parameters.abn,
        website: parameters.website,
        email: parameters.email,
        phone1: parameters.phone1,
        phone2: parameters.phone2,
        numEmployees: parameters.numEmployees,
        streetAddress: parameters.streetAddress,
        streetAddressCity: parameters.streetAddressCity,
        streetAddressState: parameters.streetAddressState,
        streetAddressPostal: parameters.streetAddressPostal,
        streetAddressCountry: parameters.streetAddressCountry,
        postalAddress: parameters.postalAddress,
        postalAddressCity: parameters.postalAddressCity,
        postalAddressState: parameters.postalAddressState,
        postalAddressPostal: parameters.postalAddressPostal,
        postalAddressCountry: parameters.postalAddressCountry,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  static updateCompany(parameters = {}) {
    return APIData.post(
      `company/update`,
      {
        company_id: parameters.companyId,
        name: parameters.name,
        organisation: parameters.organisation,
        abn: parameters.abn,
        website: parameters.website,
        email: parameters.email,
        phone1: parameters.phone1,
        phone2: parameters.phone2,
        numEmployees: parameters.numEmployees,
        streetAddress: parameters.streetAddress,
        streetAddressCity: parameters.streetAddressCity,
        streetAddressState: parameters.streetAddressState,
        streetAddressPostal: parameters.streetAddressPostal,
        streetAddressCountry: parameters.streetAddressCountry,
        postalAddress: parameters.postalAddress,
        postalAddressCity: parameters.postalAddressCity,
        postalAddressState: parameters.postalAddressState,
        postalAddressPostal: parameters.postalAddressPostal,
        postalAddressCountry: parameters.postalAddressCountry,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  static addProperty(parameters = {}) {
    return APIData({
      method: "post",
      url: "property",
      data: parameters,
      headers: { Authorization: `Bearer ${access_token}` },
    });
  }

  static addPropertyWithFiles(parameters = {}) {
    return APIData({
      method: "post",
      url: "property",
      data: parameters,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static sendInvite(parameters) {
    return APIData.get(`invite/${parameters.id}?type=${parameters.type}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getOptions(parameters) {
    return APIData.get(`options/${parameters}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getInvironment(parameters) {
    return APIData.get(`company/${parameters}/environments`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getCompanyProperties(parameters = {}) {
    return APIData.get(`company/${parameters}/properties`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getCompanyPropertyOptions(parameters = {}) {
    return APIData.get(`company/${parameters}/property-options`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static updateCompanyProperties(parameters = {}) {
    return APIData({
      method: "post",
      url: "property/update",
      data: parameters,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static updateCompanyPropertiesWithFiles(parameters = {}) {
    return APIData({
      method: "post",
      url: "property/update",
      data: parameters,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static getWebsiteDetails(id = null) {
    if(id) {
      return APIData({
        method: "get",
        url: `property/${id}`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "multipart/form-data",
        },
      });
    }
  }

  static getWebsiteLogs(id, search = "") {
console.log('::search', search);
    if(id) {
      return APIData({
        method: "get",
        url: `website/logs/${id}?search=${search}`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
    }
  }

  static createWebsiteLog(parameters = {}) {
    return APIData({
      method: "post",
      url: "website/logs",
      data: parameters,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getWebsiteNotes(id) {
    if(id) {
      return APIData({
        method: "get",
        url: `website/${id}/notes`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
    }
  }

  static getHosted(parameters) {
    return APIData.get(`options/hosted-by`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static inboxList(parameters) {
    return APIData.get(`inbox`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static publicReply(parameters) {
    return APIData({
      method: "post",
      url: "reply",
      data: parameters,
      headers: {
        Authorization: `Bearer  ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    // return APIData.post(`reply`,
    //     {
    //         is_public: parameters.is_public,
    //         cc: parameters.cc,
    //         to: parameters.to,
    //         email_id: parameters.email_id,
    //         task_id: parameters.task_id,
    //         body: parameters.body,
    //         files: parameters.files
    //     }, {
    //         headers: {
    //             'Authorization': `Bearer  ${access_token}`
    //         }
    //     }
    // );
  }

  static sendNewEmail(parameters = {}) {
    return APIData({
      method: "post",
      url: "email/new",
      data: parameters,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getComment(parameters = {}) {
    return APIData({
      method: "get",
      url: `task/${parameters}/comments`,
      headers: {
        Authorization: `Bearer  ${access_token}`,
      },
    });
  }

  static deleteEmail(parameters = {}) {
    return APIData.post(
      `email/delete`,
      {
        id: parameters,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  static contactLogsCustomer(parameters = {}) {
    return APIData({
      method: "get",
      url: `user/${parameters}/contact-logs`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static contactLogsCompany(parameters = {}) {
    return APIData({
      method: "get",
      url: `company/${parameters}/contact-logs`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static invoices(parameters = {}) {
    return APIData({
      method: "get",
      url: `companies/${parameters}/invoices`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static savePhoneCalls(parameters = {}) {
    return APIData.post(
      `phone-calls/new`,
      {
        contact_id: parameters.contact_id,
        subject: parameters.subject,
        is_incoming: parameters.is_incoming,
        details: parameters.details,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  static updatePhoneCall(parameters = {}) {
    return APIData.post(
      `phone-calls/update`,
      {
        contact_id: parameters.contact_id,
        subject: parameters.subject,
        is_incoming: parameters.is_incoming,
        details: parameters.details,
        phone_call_id: parameters.phone_call_id,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  static searchContacts(parameters = {}) {
    return APIData({
      method: "get",
      url: `contacts/search/${parameters}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static searchCompanies(parameters = {}) {
    return APIData({
      method: "get",
      url: `companies/search/${parameters}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static searchInbox(parameters = {}) {
    return APIData({
      method: "get",
      url: `inbox/search/${parameters}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  // page change function for custom DataTable pagination
  static pageChange(url) {
    return APIData({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static searchTasks(parameters = {}) {
    let url = `tasks/search/${parameters[0]}?type=${parameters[1] ?? ""}&view=${
      parameters[2] ?? "kanban"
    }&company_id=${parameters[3] ?? ""}&coder_id=${parameters[4] ?? ""}`;

    return APIData({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static searchMyTasks(parameters = {}) {
    let url = `task/my-requests-kanban/search/${parameters[0]}?type=${
      parameters[1] ?? ""
    }&view=${parameters[2] ?? "kanban"}`;
    return APIData({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  // create new tag
  static saveTag(parameters = {}) {
    return APIData.post(
      `tags`,
      {
        title: parameters.title,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // send proposal
  static sendProposal(parameters = {}) {
    return APIData.post(`send-proposal`, parameters, {
      headers: { Authorization: `Bearer ${access_token}` },
    });
  }

  // return tags as options ([{value: value, label: label}]) with assigned tags
  static getTagOptionsWithAssignedTags(parameters = {}) {
    return APIData({
      method: "get",
      url: `tag-options/${parameters[0]}/${parameters[1]}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  // create new tag
  static assignTag(parameters = {}) {
    return APIData.post(
      `assign-tag`,
      {
        tag_id: parameters.tag_id,
        target_id: parameters.target_id,
        type: parameters.type,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  static getCompanyTags(parameters = {}) {
    return APIData({
      method: "get",
      url: `company/${parameters}/tags`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getUserTags(parameters = {}) {
    return APIData({
      method: "get",
      url: `user/${parameters}/tags`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  // add new note
  static addNote(parameters = {}) {
    return APIData({
      method: "post",
      url: "notes",
      data: parameters,
      headers: {
        Authorization: `Bearer  ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  // update notes
  static updateNote(parameters = {}) {
    return APIData({
      method: "post",
      url: "notes/update",
      data: parameters,
      headers: {
        Authorization: `Bearer  ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static getCompanyNotes(parameters = {}) {
    return APIData({
      method: "get",
      url: `company/${parameters}/notes`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getCompanyRelatedContacts(parameters = {}) {
    return APIData({
      method: "get",
      url: `company/${parameters}/related-contacts`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getUserNotes(parameters = {}) {
    return APIData({
      method: "get",
      url: `user/${parameters}/notes`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static sidebarRequestFilterOptions(parameters = "request") {
    return APIData({
      method: "get",
      url: `sidebar-request-filter-options/${parameters}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static advanceSearchTasks(parameters = {}) {
    let url = "";
    let data = "";
    console.log("::parameters", parameters);

    // field filter
    parameters.data.map((parameter) => {
      data += `${parameter.main.value || ""}_filter={"condition": "${
        parameter.operator.value || ""
      }", "value": [${parameter.condition.value || ""}]}&`;
    });

    // date filter
    parameters.dateFilters.map((parameter) => {
      data += `filter_due_date_from=${parameter.due_date_from}&filter_due_date_to=${parameter.due_date_to}&`;
    });

    // date created filter
    const dateCreatedFilters = parameters.dateCreatedFilters?.[0];
    if(dateCreatedFilters) {
      data += `date_created_filter=${dateCreatedFilters.main.value ?? ''}&filter_date_created_from=${dateCreatedFilters.date_created_from ?? ''}&filter_date_created_to=${dateCreatedFilters.date_created_to ?? ''}`;
    }

    url = `task/0/requests-kanban/?${data}`;
    return APIData({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static filterTasks(parameters = {}) {
    // let url = "";
    // if (parameters[0] == null && parameters[1] == null) {
    //   url = `tasks/filter/?type=${parameters[2] ?? ""}&page=${parameters[3] ?? ""}`;
    // } else if (parameters[0] == null) {
    //   url = `tasks/filter/?coder_id=${parameters[1] ?? ""}&type=${parameters[2] ?? ""}&page=${parameters[3] ?? ""}`;
    // } else if (parameters[1] == null) {
    //   url = `tasks/filter/?company_id=${parameters[0] ?? ""}&type=${parameters[2] ?? ""}&page=${parameters[3] ?? ""}`;
    // } else {
    //   url = `tasks/filter/?company_id=${parameters[0] ?? ""}&coder_id=${parameters[1] ?? ""}&type=${parameters[2] ?? ""}&page=${parameters[3] ?? ""}`;
    // }
    let url = "tasks/filter/";
    url =
      url +
      "?" +
      objectToURLSearchParams({
        company_id: parameters[0],
        coder_id: parameters[1],
        type: parameters[2],
        page: parameters[3],
      });

    console.log("url: ", url);

    return APIData({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static archiveEmails(parameters = {}) {
    return APIData.post(
      `emails/archive`,
      {
        emails: parameters,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  static getImapAccounts() {
    return APIData({
      method: "get",
      url: `imap/retrieve/accounts/active`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static forgotPasswordEmail(email = "") {
    return APIData.post(`forgot-password`, {
      email: email,
    });
  }

  static resetPassword(parameters = {}) {
    return APIData.post(`reset-password`, {
      token: parameters.token,
      password: parameters.password,
    });
  }

  static changePassword(parameters = {}) {
    return APIData.post(
      `change-password`,
      {
        old_password: parameters.oldPw,
        new_password: parameters.newPw,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  static checkContactCompany(parameters = {}) {
    return APIData({
      method: "get",
      url: `check-contact-company?from=${parameters[0]}&to=${parameters[1]}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getPricingMethodOptions() {
    return APIData({
      method: "get",
      url: `pricing-method-options`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  // PROPERTY NOTES API ROUTES
  static getAllPropertyNoteTypes() {
    return APIData.get("property-note-types", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getPropertyNotes() {
    return APIData.get("property-notes", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static addPropertyNote(parameters) {
    return APIData.post(`property-note`, parameters, {
      headers: { Authorization: `Bearer  ${access_token}` },
    });
  }

  static updatePropertyNote(parameters) {
    return APIData.post(`property-note/update`, parameters, {
      headers: { Authorization: `Bearer  ${access_token}` },
    });
  }

  // DOMAIN API ROUTES
  static getAllDomainTypes() {
    return APIData.get("domain-types", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getDomains() {
    return APIData.get("domains", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static addDomain(parameters) {
    return APIData.post(`domain`, parameters, {
      headers: { Authorization: `Bearer  ${access_token}` },
    });
  }

  static updateDomain(parameters) {
    return APIData.post(`domain/update`, parameters, {
      headers: { Authorization: `Bearer  ${access_token}` },
    });
  }

  // TOGGLE READ/SEEN EMAIL
  static readEmail(parameters) {
    return APIData.post(`toggle-seen`, parameters, {
      headers: { Authorization: `Bearer  ${access_token}` },
    });
  }

  // MEMBER API ROUTES
  static getMembers() {
    return APIData.get("members", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static addMember(parameters) {
    return APIData.post(`member/new`, parameters, {
      headers: {
        Authorization: `Bearer  ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static updateMember(parameters) {
    return APIData.post(`member/update`, parameters, {
      headers: {
        Authorization: `Bearer  ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static getUserLevels() {
    return APIData.get("user-levels", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  // TEAM API ROUTES
  static getTeams() {
    return APIData.get("teams", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static addTeam(parameters) {
    return APIData.post(`team/new`, parameters, {
      headers: {
        Authorization: `Bearer  ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static updateTeam(parameters) {
    return APIData.post(`team/update`, parameters, {
      headers: {
        Authorization: `Bearer  ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static removeMember(parameters) {
    return APIData.post(`remove-member`, parameters, {
      headers: {
        Authorization: `Bearer  ${access_token}`,
      },
    });
  }

  // add new request status column
  static addRequestStatusColumn(parameters = {}) {
    return APIData.post(
      `status/request/new`,
      {
        title: parameters.title,
        status_title: parameters.status_title,
        record_type: parameters.record_type,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // add new project status column
  static addProjectStatusColumn(parameters = {}) {
    return APIData.post(
      `status/request/new`,
      {
        title: parameters.title,
        status_title: parameters.status_title,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // update status column
  static updateStatusColumn(parameters = {}) {
    return APIData.post(
      `status/update`,
      {
        id: parameters.id,
        title: parameters.title,
        status_title: parameters.status_title,
        position: parameters.position,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // update status position
  static updateStatusColumn(parameters = {}) {
    return APIData.post(
      `status/update-position`,
      {
        id: parameters.id,
        source: parameters.source,
        destination: parameters.destination,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // update status column
  static updateColumnName(parameters = {}) {
    return APIData.post(
      `status/column/update`,
      {
        id: parameters.id,
        title: parameters.title,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  static storeReport(parameters = {}) {
    return APIData({
      method: "post",
      url: "report",
      data: parameters,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  // update task progress
  static updateTaskProgress(parameters = {}) {
    return APIData.post(
      `task/update/progress`,
      {
        task_id: parameters.id,
        progress: parameters.progress,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  static toggleTaskRequiresDeployments(task_id = null) {
    return APIData.post(
      `task/toggle/requires-deployments`,
      {
        task_id,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  static updateTaskDeploymentNotes(parameters = {}) {
    return APIData.post(
      `task/update/deployment-notes`,
      {
        task_id: parameters.task_id,
        notes: parameters.notes,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // generate contact's access token
  static generateContactToken(parameters = {}) {
    return APIData.post(
      `contact-token`,
      {
        contact: parameters,
        access_token: access_token,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // update task progress
  static addManualPayment(parameters = {}) {
    return APIData.post(
      `companies/manual-payment`,
      {
        company: parameters.company,
        contact: parameters.contact,
        credits: parameters.credits,
        amount: parameters.amount,
        date: parameters.date,
        referenceNo: parameters.referenceNo,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  /** REFERRAL APIS */
  // retrieve referral options
  static getReferrerOptions(id = 0) {
    return APIData.get(`referrer-option/${id}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  // toggle referral feature status
  static toggleReferralStatus(company_id) {
    return APIData.post(
      `referrer-option`,
      {
        company_id,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // update tier rate
  static updateTierRate(company_id, rate, tier) {
    return APIData.post(
      `set-tier-rate`,
      {
        company_id: company_id,
        tier: tier,
        rate: rate,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // update referrer
  static updateReferrer(parameters = {}) {
    return APIData.post(`set-referrer`, parameters, {
      headers: { Authorization: `Bearer ${access_token}` },
    });
  }

  // update first referrer
  static updateFirstReferrer(company_id, referrer_id) {
    return APIData.post(
      `set-referrer`,
      {
        company_id: company_id,
        first_referrer: referrer_id,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // update second referrer
  static updateSecondReferrer(company_id, referrer_id) {
    return APIData.post(
      `set-referrer`,
      {
        company_id: company_id,
        second_referrer: referrer_id,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // retrieve company commissions
  static getCommissions(parameters = {}) {
    const url = `commissions/${parameters.company_id}/${parameters.query}`;

    return APIData.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  // retrieve user type options for contact profile
  static getUserTypeOptions(parameters = {}) {
    return APIData.get(`user-type-options`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  // retrieve user group options for contact profile
  static getUserGroupOptions(parameters = {}) {
    return APIData.get(`user-group-options`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  // update second referrer
  static syncActualTime(id) {
    return APIData.post(
      `sync-actual-time`,
      {
        id,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // set email assignee
  static setEmailAssignee(email_id, user_id) {
    return APIData.post(
      `set-email-assignee`,
      {
        email_id,
        user_id,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // remove email assignee
  static removeEmailAssignee(email_id) {
    return APIData.post(
      `remove-email-assignee`,
      {
        email_id,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // add email follower
  static addEmailFollower(email_id, user_id) {
    return APIData.post(
      `add-email-follower`,
      {
        email_id,
        user_id,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // remove email follower
  static removeEmailFollower(email_id, user_id) {
    return APIData.post(
      `remove-email-follower`,
      {
        email_id,
        user_id,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  // replace email follower
  static replaceEmailFollower(email_id, user_id_curr, user_id_new) {
    return APIData.post(
      `replace-email-follower`,
      {
        email_id,
        user_id_curr,
        user_id_new,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  static retrievePropertiesByAssigneeIds(assigneeIds) {
    return APIData.get(`get-property-assignees?assigneeIds=${assigneeIds}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static retrieveAssigneesByPropertyIds(parameters) {
    return APIData.get(`get-property-assignees/${parameters}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static storePropertyAssigneeRecord(email_id, user_id_curr, user_id_new) {
    return APIData.post(
      `replace-email-follower`,
      {
        email_id,
        user_id_curr,
        user_id_new,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
  }

  static removeNoteAttachments(parameters) {
    return APIData.post(`remove-note-attachments`, parameters, {
      headers: { Authorization: `Bearer  ${access_token}` },
    });
  }

  static retrieveNoteAttachments(parameters) {
    return APIData.get(`retrieve-note-attachments/${parameters}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  static getOrgDetails(parameters) {
    if (!parameters) return Promise.reject("Org ID is required");
    return APIData({
      method: "get",
      url: `company/${parameters}/credits`,
      headers: {
        Authorization: `Bearer  ${access_token}`,
      },
    });
  }

  static updateHourlyRate(parameters = {}) {
    return APIData.post(`company/update/hourly-rate`, parameters, {
      headers: { Authorization: `Bearer ${access_token}` },
    });
  }

  static getAfterSalesCareCost() {
    return APIData({
      method: "get",
      url: `cost-after-sales-care`,
      headers: {
        Authorization: `Bearer  ${access_token}`,
      },
    });
  }

  static getCompletedWork() {
    return APIData({
      method: "get",
      url: `completed-work`,
      headers: {
        Authorization: `Bearer  ${access_token}`,
      },
    });
  }

  static getSettings() {
    return APIData({
      method: "get",
      url: `settings`,
      headers: {
        Authorization: `Bearer  ${access_token}`,
      },
    });
  }

  static updateSettings(parameters = {}) {
    return APIData.post(`settings`, parameters, {
      headers: { Authorization: `Bearer ${access_token}` },
    });
  }

  static getUserPreferences(dateCreatedFiltersOnly = null) {
    return APIData({
      method: "get",
      url: `user-preferences?date_created_filters_only=${dateCreatedFiltersOnly}`,
      headers: {
        Authorization: `Bearer  ${access_token}`,
      },
    });
  }
}
