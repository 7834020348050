import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";

export default function Datepicker(props) {
  const calendarRef = useRef();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (props.date) {
      setDate(Date.parse(props.date));
    }

    if (props.open) {
      calendarRef.current.setOpen(true);
    }
  }, [props.date]);

  return (
    <React.Fragment>
      <DatePicker
        className={`${
          props.allowPreviousDates ? "" : "form-control form-control-date"
        }`}
        placeholderText={"Select Date"}
        selected={date}
        minDate={props.allowPreviousDates ? "" : new Date()}
        maxDate={props.allowFutureDates ? "" : new Date()}
        onChange={props.updateDueDate}
        ref={calendarRef}
        dateFormat={
          props.timeOnly
            ? "h:mm aa"
            : `dd MMM yyyy${props.allowPreviousDates || props.allowFutureDates ? "" : ", hh:mm aa"}`
        }
        showTimeSelect={props.showTimeSelect}
        showTimeSelectOnly={props.timeOnly}
        showTimeInput={props.showTimeInput}
      />
    </React.Fragment>
  );
}
