import React, { useState, useEffect } from 'react';

import { connect } from "react-redux";
import { UncontrolledDropdown } from "reactstrap";

import { Link } from "react-router-dom";
import moment from 'moment';

// Reactstrap
import { DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import megamenuImg from "../../assets/images/megamenu-img.png";
import logo from "../../assets/images/jiffi_logo.svg";
// import logoLight from "../../assets/images/jiffi_logo.svg";
import logoLightSvg from "../../assets/images/jiffi_logo.svg";
import logoDark from "../../assets/images/jiffi_logo.svg";

// // import images
// import github from "../../assets/images/brands/github.png";
// import bitbucket from "../../assets/images/brands/bitbucket.png";
// import dribbble from "../../assets/images/brands/dribbble.png";
// import dropbox from "../../assets/images/brands/dropbox.png";
// import mail_chimp from "../../assets/images/brands/mail_chimp.png";
// import slack from "../../assets/images/brands/slack.png";

//i18n
import { withNamespaces } from 'react-i18next';

import { searchCompanies, searchContacts, searchInbox, searchTasks, advanceSearchTasks, searchProperties, weeklyWagesCost, weeklyOtherOverhead, costOfAfterSalesCare, completedWork } from "../../store/actions";
import Api from "../../Utils/API";
import Select, { components } from "react-select";
import { SelectionState } from 'draft-js';
import DatepickerDiv from "../Datepicker";

const customStyles = {
    container: base => ({
        marginRight: '5px'
    }),
    control: base => ({
        ...base,
        background: '#F6F7FA',
        borderRadius: '4px',
        padding: '0 10px',
        border: 'none',
        minWidth: '160px'
    }),
    valueContainer: base => ({
        ...base,
        padding: 0
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 0
    })
}

const customStyles_2 = {
    container: base => ({
        marginRight: '5px'
    }),
    control: base => ({
        ...base,
        background: '#F6F7FA',
        borderRadius: '4px',
        padding: '0 10px',
        border: 'none',
        minWidth: '135px'
    }),
    valueContainer: base => ({
        ...base,
        padding: 0
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 0
    })
}

const Header = ({Profitability, Company, setsearch, setAdvanceSearch, setWeeklyWagesCost, setWeeklyOtherOverhead, setCostOfAfterSalesCare, setCompletedWork, ...props}) => {
    const search = Company.searchCompanies
    const [dateFilters, setDateFilters] = useState({
        fields: [
            {
                main: [
                    { value: 'custom_date', label: 'Custom Date' },
                    { value: 'yesterday', label: 'Yesterday' },
                    { value: 'today', label: 'Today' },
                    { value: 'tomorrow', label: 'Tomorrow' },
                    { value: 'last_week', label: 'Last Week' },
                    { value: 'this_month', label: 'This Month' },
                    { value: 'last_90_days', label: 'Last 90 Days' },
                ],
                due_date_from: '',
                due_date_to: '',
            }
        ]
    })
    const [filters, setFilters] = useState({
        fields: [
            {
                main: [
                    {value: 'company', label: 'Company'},
                    {value: 'assignee', label: 'Assignee'},
                    {value: 'task', label: 'Task'},
                    {value: 'ticket_no', label: 'Ticket #'}
                ],
                operator: [
                    {value: 'contains', label: 'Contains'},
                    {value: 'excludes', label: 'Excludes'},
                ],
                condition: []
            },
            {
                main: [
                    {value: 'company', label: 'Company'},
                    {value: 'assignee', label: 'Assignee'},
                    {value: 'task', label: 'Task'},
                    {value: 'ticket_no', label: 'Ticket #'}
                ],
                operator: [
                    {value: 'contains', label: 'Contains'},
                    {value: 'excludes', label: 'Excludes'},
                ],
                condition: []
            },
            {
                main: [
                    {value: 'company', label: 'Company'},
                    {value: 'assignee', label: 'Assignee'},
                    {value: 'task', label: 'Task'},
                    {value: 'ticket_no', label: 'Ticket #'}
                ],
                operator: [
                    {value: 'contains', label: 'Contains'},
                    {value: 'excludes', label: 'Excludes'},
                ],
                condition: []
            },
            {
                main: [
                    {value: 'company', label: 'Company'},
                    {value: 'assignee', label: 'Assignee'},
                    {value: 'task', label: 'Task'},
                    {value: 'ticket_no', label: 'Ticket #'}
                ],
                operator: [
                    {value: 'contains', label: 'Contains'},
                    {value: 'excludes', label: 'Excludes'},
                ],
                condition: []
            },
        ]
    });
    
    const [appliedDateFilters, setAppliedDateFilters] = useState([
        {main: '', due_date_from: '', due_date_to: ''},
    ]);

    // set default value on load
    const [appliedDateCreatedFilters, setAppliedDateCreatedFilters] = useState([
        {main: '', date_created_from: '', date_created_to: ''},
    ]);
    const [appliedFilters, setAppliedFilters] = useState([
        {main: {value: 'company', label: 'Company'}, operator: '', condition: ''},
        {main: {value: 'assignee', label: 'Assignee'}, operator: '', condition: ''},
        {main: {value: 'task', label: 'Task'}, operator: '', condition: ''},
        {main: {value: 'ticket_no', label: 'Ticket #'}, operator: '', condition: ''}
    ]);
    const [companiesList, setCompanies] = useState([]);
    const [codersList, setCoders] = useState([]);
    const [tasksList, setTasks] = useState([]);
    const [ticketnosList, setTicketNos] = useState([]);
    const [checkField, setCheckField] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [pathName, setPathName] = useState('');
    const [currentInput, setCurrentInput] = useState('');
    const [prevCurrentInput, setPrevCurrentInput] = useState('');
    const [megaMenu, setmegaMenu] = useState(false);
    const [socialDrp, setsocialDrp] = useState(false);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    function searchSkynet(e) {
        e.preventDefault();

        if(window.location.href.indexOf("email-inbox") > -1) {
            // search emails
            props.searchInbox(search);
        } else if(window.location.href.indexOf("tasks-kanban") > -1) {
            // search requests
            props.searchTasks(search);
        } else if(window.location.href.indexOf("contacts-list") > -1) {
            // search contacts
            props.searchContacts(search);
        } else if(window.location.href.indexOf("companies-list") > -1) {
            // search companies
            props.searchCompanies(search);
        }
    }

    function toggleFullscreen() {
        if (
            !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    useEffect(() => {
        getSettings();
        getAfterSalesCareCost();
        getCompletedWork();

        let path_name = window.location.pathname.split("-")
        let selected_path = path_name[0] == '/email' ? path_name[1] : path_name[0];
        path_name = selected_path.replace('/', '')
        setPathName(path_name)

        if(path_name == 'tasks'){
            companies();
            coders();
            tasks();
            ticketnos();
            userPreferences(1);
            return document.body.classList.add("no-scroll");
        }

        return document.body.classList.remove("no-scroll");
    }, [])

    const userPreferences = (dateCreatedFiltersOnly = null) => {
        Api.getUserPreferences(dateCreatedFiltersOnly).then(({data}) => {
            let filter = data.task_date_created_filter;

            if(filter) {
                if(filter.includes('custom_date')) {
                    filter = JSON.parse(filter);

                    setDateCreatedFilter('main', {value: "custom_date", label: "Custom Date"}, 0, {from: filter.from, to: filter.to});

                    return;
                }

                const defaultDateCreatedFilter = dateFilters.fields[0].main[dateFilters.fields[0].main.findIndex(item => item.value == filter)];

                setDateCreatedFilter('main', defaultDateCreatedFilter, 0);
            }
        }).catch(error => {
            console.log("::userPreferences > error", error);
        })
    }

    const companies = () => {
        Api.getCompanies().then(result => {
            let companies = result.data;    // get companies
            let company_options = [];       // used on <Select>
            companies.forEach(item => {
                company_options.push({
                    label: item.name,
                    value: item.id
                })
            })

            filters.fields[0].condition = company_options 
            setCompanies(company_options);
        }).catch(error => {
            console.log(error)
        })
    }

    const coders = () => {
        Api.allCoders().then(result => {
            let coders = result.data;       // get coders
            let coder_options = [];       // used on <Select>
            coders.forEach(item => {
                coder_options.push({
                    label: `${item.firstname} ${item.surname}`,
                    value: item.id
                })
            })

            filters.fields[1].condition = coder_options 
            setCoders(coder_options);
        }).catch(error => {
            console.log(error)
        })
    }

    const tasks = () => {
        Api.allTask().then(result => {
            let tasks = result.data;       // get tasks
            let task_options = [];       // used on <Select>
            tasks.forEach(item => {
                task_options.push({
                    label: item.text,
                    value: item.id
                })
            })

            filters.fields[2].condition = task_options 
            setTasks(task_options);
        }).catch(error => {
            console.log(error)
        })
    }

    const ticketnos = () => {
        Api.allTask().then(result => {
            let tasks = result.data;       // get tasks ticket #
            let task_nos = [];             // used on <Select>
            tasks.forEach(item => {
                task_nos.push({
                    label: item.task_number,
                    value: item.id
                })
            })

            filters.fields[3].condition = task_nos 
            setTicketNos(task_nos);
        }).catch(error => {
            console.log(error)
        })
    }

    // Advance Search --------------------------------------------------------------------

    // * Toggler *
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const setFilter = (key, e, i) => {
        // main in fields state checker, change condition according to the selected main
        filters.fields[i].condition = e.value == 'company' ? companiesList : e.value == 'assignee' ? codersList : e.value == 'task' ? tasksList : e.value == 'ticket_no' ? ticketnosList : filters.fields[i].condition;
        setFilters(filters);

        appliedFilters[i][key] = e;         // set applied filter that will be send to server
        return setCheckField(!checkField)   // checker to update the states on filters
    }

    const setDateFilter = (key, e, i) => {
        let date_now = moment().startOf('day').format("YYYY-MM-DD");
        let date_tomorrow = moment().add(1, 'days').format("YYYY-MM-DD");
        let date_yesterday = moment().subtract(1, 'days').format("YYYY-MM-DD");
        let date_lastweek = moment().subtract(7,'days').format("YYYY-MM-DD");
        let date_start_month = moment().startOf('month').format("YYYY-MM-DD");
        let date_end_month = moment().endOf('month').format("YYYY-MM-DD");
        let date_last_90_days = moment().subtract(90,'days').format("YYYY-MM-DD");
        appliedDateFilters[i][key] = e;     // set applied filter

        if(e.value == 'custom_date'){
            updateDueDate(date_now, 'due_date_from');  //  from - yesterday's date
            updateDueDate(date_now, 'due_date_to');    //  to - yesterday's date
        }
        if(e.value == 'yesterday'){
            updateDueDate(date_yesterday, 'due_date_from');  //  from - yesterday's date
            updateDueDate(date_yesterday, 'due_date_to');    //  to - yesterday's date
        }
        if(e.value == 'today'){
            updateDueDate(date_now, 'due_date_from');  //  from - yesterday's date
            updateDueDate(date_now, 'due_date_to');    //  to - yesterday's date
        }
        if(e.value == 'tomorrow'){
            updateDueDate(date_tomorrow, 'due_date_from');  //  from - yesterday's date
            updateDueDate(date_tomorrow, 'due_date_to');    //  to - yesterday's date
        }
        if(e.value == 'last_week'){
            updateDueDate(date_lastweek, 'due_date_from');  //  from - yesterday's date
            updateDueDate(date_now, 'due_date_to');    //  to - yesterday's date
        }
        if(e.value == 'this_month'){
            updateDueDate(date_start_month, 'due_date_from');  //  from - yesterday's date
            updateDueDate(date_end_month, 'due_date_to');    //  to - yesterday's date
        }
        if(e.value == 'last_90_days'){
            updateDueDate(date_last_90_days, 'due_date_from');  //  from - yesterday's date
            updateDueDate(date_last_90_days, 'due_date_to');    //  to - yesterday's date
        }

        return setCheckField(!checkField)   // checker to update the states on filters
    }

    const setDateCreatedFilter = (key, e, i, customDates = null) => {
        const filter = e.value;

        if(!['custom_date', 'yesterday', 'today', 'last_week', 'this_month', 'last_90_days'].includes(filter)) {
            return;
        }

        let date_now = moment().startOf('day').format("YYYY-MM-DD");
        let date_yesterday = moment().subtract(1, 'days').format("YYYY-MM-DD");
        let date_lastweek = moment().subtract(7,'days').format("YYYY-MM-DD");
        let date_start_month = moment().startOf('month').format("YYYY-MM-DD");
        let date_end_month = moment().endOf('month').format("YYYY-MM-DD");
        let date_last_90_days = moment().subtract(90,'days').format("YYYY-MM-DD");
        appliedDateCreatedFilters[i][key] = e;     // set applied filter

        setAppliedDateCreatedFilters((prevState) => ({
            ...prevState,
            ...appliedDateCreatedFilters
        }));

        if(filter == 'custom_date') {
            updateDateCreated(customDates ? moment(customDates.from).format("YYYY-MM-DD") : date_now, 'date_created_from');
            updateDateCreated(customDates ? moment(customDates.to).format("YYYY-MM-DD") : date_now, 'date_created_to');
        }

        if(filter == 'yesterday') {
            updateDateCreated(date_yesterday, 'date_created_from');
            updateDateCreated(date_yesterday, 'date_created_to');
        }

        if(filter == 'today') {
            updateDateCreated(date_now, 'date_created_from');
            updateDateCreated(date_now, 'date_created_to');
        }

        if(filter == 'last_week') {
            updateDateCreated(date_lastweek, 'date_created_from');
            updateDateCreated(date_now, 'date_created_to');
        }

        if(filter == 'this_month') {
            updateDateCreated(date_start_month, 'date_created_from');
            updateDateCreated(date_end_month, 'date_created_to');
        }

        if(filter == 'last_90_days') {
            updateDateCreated(date_last_90_days, 'date_created_from');
            updateDateCreated(date_now, 'date_created_to');
        }

        return setCheckField(!checkField);
    }

    // * Apply Filter *
    const applyFilter = () => {
        setAdvanceSearch({data: appliedFilters, dateFilters: appliedDateFilters, dateCreatedFilters: appliedDateCreatedFilters, filterCheck: checkField});
        toggle();
    }

    // * Clear Filter *
    const clearFilter = () => {
        setAppliedFilters([
            {main: '', operator: '', condition: ''},
            {main: '', operator: '', condition: ''},
            {main: '', operator: '', condition: ''},
            {main: '', operator: '', condition: ''}
        ])
        setAppliedDateFilters([
            {main: '', due_date_from: '', due_date_to: ''},
        ])
        setAppliedDateCreatedFilters([
            {main: '', due_date_from: '', due_date_to: ''},
        ])
    }

    // * Update Due Date *
    const updateDueDate = (e, key_date) => {
        let dueDate = moment(e).format("YYYY-MM-DD");
        appliedDateFilters[0][key_date] = dueDate;
        return setCheckField(!checkField)
    }

    // * Update Date Created *
    const updateDateCreated = (e, key_date) => {
        let dateCreated = moment(e).format("YYYY-MM-DD");
        appliedDateCreatedFilters[0][key_date] = dateCreated;

        setAppliedDateCreatedFilters((prevState) => ({
            ...prevState,
            ...appliedDateCreatedFilters
        }));
        return setCheckField(!checkField)
    }

    // * Checker *
    useEffect(() => {
        console.log("::advance filters", filters);
    }, [checkField])

    // * Profitability and Settings APIs *
    const getSettings = async() => {
        await Api.getSettings().then(({data}) => {
            setWeeklyWagesCost(data.weekly_wages);
            setWeeklyOtherOverhead(data.weekly_overhead);
        }).catch(error => {
            console.log('Header > getSettings > error', error);
        });
    }

    const getAfterSalesCareCost = async() => {
        await Api.getAfterSalesCareCost().then(({data}) => {
            setCostOfAfterSalesCare({
                hours: data.hours,
                amount: data.amount
            });
        }).catch(error => {
            console.log('Header > getAfterSalesCareCost > error', error);
        });
    }

    const getCompletedWork = async() => {
        await Api.getCompletedWork().then(({data}) => {
            setCompletedWork({
                hours: data.hours,
                amount: data.amount
            });
        }).catch(error => {
            console.log('Header > getCompletedWork > error', error);
        });
    }

    // * Add Field when pressing "+" icon *
    const addField = () => {
        filters.fields.push({
            main: [
                {value: 'company', label: 'Company'},
                {value: 'assignee', label: 'Assignee'},
                {value: 'task', label: 'Task'},
                {value: 'ticket_no', label: 'Ticket #'}
            ],
            operator: [
                {value: 'contains', label: 'Contains'},
                {value: 'excludes', label: 'Excludes'},
            ],
            condition: []
        })

        appliedFilters.push({main: '', operator: '', condition: ''})
        return setCheckField(!checkField)
    }

    // * Remove Field when pressing trash icon *
    const removeField = (i) => {
        filters.fields.splice(i, 1);
        appliedFilters.splice(i, 1);
        return setCheckField(!checkField)
    }

    // Menu Toggler ------------------------------------------------------------------------
    function tToggle() {
        props.toggleLeftmenu(!props.leftMenu);
        if (props.leftSideBarType === "default") {
            props.changeSidebarType("condensed", isMobile);
        } else if (props.leftSideBarType === "condensed") {
            props.changeSidebarType("default", isMobile);
        }
    }

    const handleSearch = (value) => {
        setPrevCurrentInput(value);
        setTimeout(() => {
            setCurrentInput(value);
        }, 500);
    }

    useEffect(() => {
        if (prevCurrentInput == currentInput && setsearch)
            setsearch(currentInput);
    }, [currentInput, prevCurrentInput]);


    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to="/" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logo} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoDark} alt="" height="17" />
                                </span>
                            </Link>

                            <Link to="/" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logoLightSvg} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoLightSvg} alt="" height="44" width="74" />
                                </span>
                            </Link>
                        </div>
                        <button type="button" onClick={() => { tToggle() }} className="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn">
                            <i className="fa fa-fw fa-bars" style={{ color: '#555B6D !important' }}></i>
                            <span className="page-title-header"> {pathName == 'tasks' ? 'requests' : (pathName == 'my' ? 'my tasks' : pathName)} </span>
                        </button>   
                        
                        <div className="app-search d-none d-lg-block">
                            <div className="position-relative">
                                <input type="text" className="form-control" placeholder={props.t('Search') + "..."} onChange={(e) => handleSearch(e.target.value) } />

                                {pathName == 'tasks' ? 
                                    <UncontrolledDropdown className="filter-dropdown" isOpen={dropdownOpen} toggle={toggle}>
                                        <DropdownToggle href="#" className="arrow-none" tag="i" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                            <i className="mdi mdi-filter-variant m-0 text-muted h5"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="p-0 dropdown-filter">
                                            <div className="filter-container">
                                                <div className="filter-header">Advance Search</div>
                                                <div className="filter-body">
                                                    <div className="row m-0">
                                                        <div className="col-12 filter-select-field p-0">Select Fields</div>
                                                            {filters.fields.map((filter, i) => {
                                                                return (
                                                                    <div className="row filter-field-columns p-0 m-0" key={i}>
                                                                        <div className="col p-0 mt-2">
                                                                            <Select
                                                                                styles={customStyles}
                                                                                placeholder="Select Here"
                                                                                value={appliedFilters[i].main}
                                                                                onChange={(e) => setFilter('main', e, i)}
                                                                                options={filter.main}
                                                                            />
                                                                        </div>
                                                                        <div className="col p-0 mt-2">
                                                                            <Select
                                                                                styles={customStyles_2}
                                                                                placeholder="Select Here"
                                                                                value={appliedFilters[i].operator}
                                                                                onChange={(e) => setFilter('operator', e, i)}
                                                                                options={filter.operator}
                                                                            />
                                                                        </div>
                                                                        <div className="col p-0 mt-2">
                                                                            <Select
                                                                                styles={customStyles}
                                                                                placeholder="Select Here"
                                                                                value={appliedFilters[i].condition}
                                                                                onChange={(e) => setFilter('condition', e, i)}
                                                                                options={filter.condition}
                                                                            />
                                                                        </div>
                                                                        <div className="col p-0 filter-icons-container mt-2" onClick={() => addField()}><i className="fa fa-plus filter-icons"></i></div>
                                                                        <div className="col p-0 filter-icons-container mt-2" onClick={() => removeField(i)}><i className="fa fa-trash filter-icons"></i></div>
                                                                    </div>
                                                                )
                                                            })}
                                                    </div>
                                                    <div className="row m-0 mt-3">
                                                        <div className="col-12 filter-select-daterange p-0">Select Date Range Due</div>
                                                            {dateFilters.fields.map((filter, i) => {
                                                                return (
                                                                    <div className="row filter-field-columns p-0 m-0" key={i}>
                                                                        <div className="col p-0 mt-2">
                                                                            <Select
                                                                                styles={customStyles}
                                                                                placeholder="Select Here"
                                                                                value={appliedDateFilters[i].main}
                                                                                onChange={(e) => setDateFilter('main', e, i)}
                                                                                options={filter.main}
                                                                            />
                                                                        </div>
                                                                        {appliedDateFilters[i].main.value == 'custom_date' ? 
                                                                            <div className="col p-0 mt-2 filter-date-container">
                                                                                <DatepickerDiv className="filter-date" updateDueDate={(e) => updateDueDate(e, 'due_date_from')} date={appliedDateFilters[i].due_date_from} allowPreviousDates={true}/>
                                                                            </div>
                                                                        : null}
                                                                        {appliedDateFilters[i].main.value == 'custom_date' ? 
                                                                            <div className="col p-0 mt-2 filter-date-container">
                                                                                <DatepickerDiv className="filter-date" updateDueDate={(e) => updateDueDate(e, 'due_date_to')} date={appliedDateFilters[i].due_date_to} allowPreviousDates={true}/>
                                                                            </div>
                                                                        : null}
                                                                    </div>
                                                                )
                                                            })}
                                                    </div>
                                                    <div className="row m-0 mt-3">
                                                        <div className="col-12 filter-select-daterange p-0">Select Date Range Created</div>
                                                            {dateFilters.fields.map((filter, i) => {
                                                                return (
                                                                    <div className="row filter-field-columns p-0 m-0" key={i}>
                                                                        <div className="col p-0 mt-2">
                                                                            <Select
                                                                                styles={customStyles}
                                                                                placeholder="Select Here"
                                                                                value={appliedDateCreatedFilters[0].main}
                                                                                onChange={(e) => setDateCreatedFilter('main', e, i)}
                                                                                options={filter.main.filter((item) => item.value !== 'tomorrow')}
                                                                            />
                                                                        </div>

                                                                        {appliedDateCreatedFilters[0].main.value == 'custom_date' ? 
                                                                            <div className="col p-0 mt-2 filter-date-container">
                                                                                <DatepickerDiv className="filter-date" updateDueDate={(e) => updateDateCreated(e, 'date_created_from')} date={appliedDateCreatedFilters[0].date_created_from} allowPreviousDates={true}
                                                                                allowFutureDates={false}/>
                                                                            </div>
                                                                        : null}
                                                                        {appliedDateCreatedFilters[0].main.value == 'custom_date' ? 
                                                                            <div className="col p-0 mt-2 filter-date-container">
                                                                                <DatepickerDiv className="filter-date" updateDueDate={(e) => updateDateCreated(e, 'date_created_to')} date={appliedDateCreatedFilters[0].date_created_to} allowPreviousDates={true}
                                                                                allowFutureDates={false}/>
                                                                            </div>
                                                                        : null}
                                                                    </div>
                                                                )
                                                            })}
                                                    </div>
                                                </div>
                                                <div className="filter-footer" style={{borderTop: '0.5px solid #ECEFF5'}}>
                                                    <div className="row m-0">
                                                        <div className="col-6 filter-clear pl-3" onClick={() => clearFilter()}>Clear Filters</div>
                                                        <div className="col-6 row" style={{textAlign: 'right'}}>
                                                            <div className="col-8 filter-cancel p-0" onClick={toggle}>Cancel</div>
                                                            <div className="col-4 filter-apply p-0" onClick={() => applyFilter()}>Apply</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                : null}
                                <span className="bx bx-search-alt"></span>
                            </div>
                        </div>
                    </div>
                    <div className="profitability-header">
                        <div className="kanban-org-label" style={{marginRight: "30px"}}>Cost of After Sales <span className="kanban-org-details">{Profitability.costOfAfterSalesCare.hours} / $ {parseFloat(Profitability.costOfAfterSalesCare.amount).toFixed(2)}</span></div>
                        <div className="kanban-org-label" style={{marginRight: "30px"}}>Completed Work <span className="kanban-org-details">{Profitability.completedWork.hours} / $ {parseFloat(Profitability.completedWork.amount).toFixed(2)}</span></div>
                        <div className="kanban-org-label" style={{marginRight: "30px"}}>Profit $<span className="kanban-org-details">{parseFloat(Profitability.profit).toFixed(2)}</span></div>
                    </div>
                    <div className="d-flex">
                        <div className="dropdown d-inline-block d-lg-none ml-2">
                            <button onClick={() => { setsearch(!search) }} type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown">
                                <i className="mdi mdi-magnify"></i>
                            </button>
                            <div className={search ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                aria-labelledby="page-header-search-dropdown">

                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search..." aria-label="Recipient's username" />
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="submit" onClick={() => alert(123)}><i className="mdi mdi-magnify"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* <NotificationDropdown style={{ color: '#555B6D' }} /> */}
                        <ProfileMenu />

                        {/* <div onClick={() => { props.showRightSidebarAction(!props.showRightSidebar); }} className="dropdown d-inline-block">
                            <button type="button" className="btn header-item noti-icon right-bar-toggle">
                                <i className="bx bx-cog bx-spin" style={{ color: '#555B6D' }}></i>
                            </button>
                        </div> */}

                    </div>
                </div>
            </header>
        </React.Fragment>
    );
}

const mapStatetoProps = ({Company, Task, Property, Profitability}) => {
    return { Company: Company, taskFilter: Task.taskFilter, taskAdvanceSearch: Task.taskAdvanceSearch, propertySearchString: Property.propertySearchString, Profitability: Profitability };
};

const mapDispatchToProps = (dispatch) => {
    if(window.location.href.indexOf("email-inbox") > -1) {
        return {
            setsearch:(e) => dispatch(searchInbox(e)),
            setWeeklyWagesCost: (e) => dispatch(weeklyWagesCost(e)),
            setWeeklyOtherOverhead: (e) => dispatch(weeklyOtherOverhead(e)),
            setCostOfAfterSalesCare: (e) => dispatch(costOfAfterSalesCare(e)),
            setCompletedWork: (e) => dispatch(completedWork(e))
        }
    } else if(window.location.href.indexOf("tasks-kanban") > -1) {
        return {
            setsearch:(e) => dispatch(searchTasks(e)),
            setAdvanceSearch: (e) => dispatch(advanceSearchTasks(e)),
            setWeeklyWagesCost: (e) => dispatch(weeklyWagesCost(e)),
            setWeeklyOtherOverhead: (e) => dispatch(weeklyOtherOverhead(e)),
            setCostOfAfterSalesCare: (e) => dispatch(costOfAfterSalesCare(e)),
            setCompletedWork: (e) => dispatch(completedWork(e))
        }
    } else if(window.location.href.indexOf("contacts-list") > -1) {
        return {
            setsearch:(e) => dispatch(searchContacts(e)),
            setWeeklyWagesCost: (e) => dispatch(weeklyWagesCost(e)),
            setWeeklyOtherOverhead: (e) => dispatch(weeklyOtherOverhead(e)),
            setCostOfAfterSalesCare: (e) => dispatch(costOfAfterSalesCare(e)),
            setCompletedWork: (e) => dispatch(completedWork(e))
        }
    } else if(window.location.href.indexOf("companies-list") > -1) {
        return {
            setsearch:(e) => dispatch(searchCompanies(e)),
            setWeeklyWagesCost: (e) => dispatch(weeklyWagesCost(e)),
            setWeeklyOtherOverhead: (e) => dispatch(weeklyOtherOverhead(e)),
            setCostOfAfterSalesCare: (e) => dispatch(costOfAfterSalesCare(e)),
            setCompletedWork: (e) => dispatch(completedWork(e))
        }
    } else if(window.location.href.indexOf("websites-list") > -1) {
        return {
            setsearch:(e) => dispatch(searchProperties(e)),
            setWeeklyWagesCost: (e) => dispatch(weeklyWagesCost(e)),
            setWeeklyOtherOverhead: (e) => dispatch(weeklyOtherOverhead(e)),
            setCostOfAfterSalesCare: (e) => dispatch(costOfAfterSalesCare(e)),
            setCompletedWork: (e) => dispatch(completedWork(e))
        }
    }  else {
        return {
            setWeeklyWagesCost: (e) => dispatch(weeklyWagesCost(e)),
            setWeeklyOtherOverhead: (e) => dispatch(weeklyOtherOverhead(e)),
            setCostOfAfterSalesCare: (e) => dispatch(costOfAfterSalesCare(e)),
            setCompletedWork: (e) => dispatch(completedWork(e))
        }
    }
}

export default connect(mapStatetoProps, mapDispatchToProps)(withNamespaces()(Header));

