// import React, { useState, useEffect, useCallback, useRef } from "react";
import React, { useState, useEffect } from "react";
import queryString from "query-string";
import Skeleton from "react-loading-skeleton";
import ControlledBoard from "./ControlledBoard";
import "./tasks.scss";
import Api from "../../Utils/API";
import RequestForm from "../../components/RequestForm";
import PropertyDetails from "../../components/PropertyDetails";
import LoadingIndicator from "../../components/LoadingIndicator";
import { getCookie, getFirstWord } from "../../Utils/helper";
import { connect, useDispatch } from "react-redux";
import RequestList from "../../components/Requests/RequestList";
import AddKanbanColumnForm from "../../components/AddKanbanColumnForm";
import RequestTimeline from "../../components/Requests/RequestTimeline";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import objectToURLSearchParams from "../../Utils/objectToURLSearchParams";
// import { filterTasks } from "../../store/actions";
import {Helmet} from "react-helmet";

const defaultColumns = [
  {
    id: 1,
    title: "New Request",
    cards: [],
  },
  {
    id: 2,
    title: "Proposal Sent",
    cards: [],
  },
  {
    id: 3,
    title: "Awaiting Commencement",
    cards: [],
  },
  {
    id: 4,
    title: "In Progress",
    cards: [],
  },
  {
    id: 5,
    title: "In Review",
    cards: [],
  },
  {
    id: 6,
    title: "On-Hold",
    cards: [],
  },
  {
    id: 7,
    title: "Complete",
    cards: [],
  },
  {
    id: 8,
    title: "Archive",
    cards: [],
  },
];

let userDetails = getCookie("userDetails");
let loginDetails = "";
if (userDetails) loginDetails = JSON.parse(userDetails);

const LoadingDiv = () => {
  return (
    <React.Fragment>
      <div className="row ml-3 mr-3">
        <div className="col-lg-3 loader-board mr-2">
          <div className="mb-3">
            <Skeleton height={18} width={"70%"} count={1} />
          </div>
          <div className="row-2">
            <Skeleton height={12} width={"100%"} count={4} />
          </div>
          <div className="row-3">
            <div className="menu-button">
              <Skeleton circle={true} height={30} width={30} count={1} />
              <Skeleton circle={true} height={30} width={30} count={1} />
              <Skeleton circle={true} height={30} width={30} count={1} />
            </div>
          </div>
        </div>
        <div className="col-lg-3 loader-board mr-2">
          <div className="mb-3">
            <Skeleton height={18} width={"70%"} count={1} />
          </div>
          <div className="row-2">
            <Skeleton height={12} width={"100%"} count={4} />
          </div>
          <div className="row-3">
            <div className="menu-button">
              <Skeleton circle={true} height={30} width={30} count={1} />
              <Skeleton circle={true} height={30} width={30} count={1} />
              <Skeleton circle={true} height={30} width={30} count={1} />
            </div>
          </div>
        </div>
        <div className="col-lg-3 loader-board mr-2">
          <div className="mb-3">
            <Skeleton height={18} width={"70%"} count={1} />
          </div>
          <div className="row-2">
            <Skeleton height={12} width={"100%"} count={4} />
          </div>
          <div className="row-3">
            <div className="menu-button">
              <Skeleton circle={true} height={30} width={30} count={1} />
              <Skeleton circle={true} height={30} width={30} count={1} />
              <Skeleton circle={true} height={30} width={30} count={1} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

function TaskKanban(props) {
  const history = useHistory();
  const params = queryString.parse(props.location.search);
  const url = window.location.href;
  const dispatch = useDispatch();
  let recordType = "";
  if (url.includes("tasks")) {
    recordType = "request";
  } else if (url.includes("design")) {
    recordType = "design";
  } else if (url.includes("sales")) {
    recordType = "sales";
  } else {
    recordType = "project";
  }
  const [comments, setComments] = useState([]);
  const [stateBaseUrl, setBaseUrlState] = useState("");
  const [stateOptions, setOptionState] = useState({
    coders: [],
    requestor: [],
    pricing_methods: [],
    request_types: [],
    statuses: [],
  });
  const [state, setState] = useState({
    newRequest: false,
    loading: true,
    isPdetails: false,
    requestType: "board",
// requestType: "timeline",
    task_id: params.id || 0,
    selectedProject: 0,
    newRequestData: [],
    requestDetails: [],
    propertyDetails: [],
    propertyDetailsName: "",
    baseurlINK: "",
    id: "",
    isSubmit: false,
    name: loginDetails
      ? ` ${getFirstWord(loginDetails.firstname)} ${getFirstWord(
          loginDetails.surname
        )} `
      : "",
    columns: defaultColumns,
    displayType: "kanban",
    isAddStatusColumn: false,
    isUpdateStatusColumn: false,
    view: "kanban",
    record_type: url.includes("tasks")
      ? "request"
      : url.includes("design")
      ? "design"
      : "project",
    prevY: 0,
  });
  const [orgDetails, setOrgDetails] = useState({name: '', creditBalance: '', taskBalance: ''});
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [title, setTitle] = useState("Requests | Skynet V2");
  const [metaDescription, setMetaDescription] = useState("Jiffi Web Help - CRM & Project Management");

  // // ==============================================================================================================================
  // const boardRef = useRef(null);
  // const imgData = [];
  // const taskObserver = useCallback(node => {
  //     const intObs = new IntersectionObserver(entries => {
  //         entries.forEach(en => {
  //         if (en.intersectionRatio > 0) {
  //             const currentImg = en.target;
  //             const newImgSrc = currentImg.dataset.src;
  //             // only swap out the image source if the new url exists
  //             if (!newImgSrc) {
  //             console.error('Image source is invalid');
  //             } else {
  //             currentImg.src = newImgSrc;
  //             }
  //             intObs.unobserve(node); // detach the observer when done
  //         }
  //         });
  //     });
  //     intObs.observe(node);
  // }, []);
  // useEffect(() => {
  //     boardRef.current = document.querySelectorAll('.card-img-top');
  //     if (boardRef.current) {
  //         boardRef.current.forEach(task => taskObserver(task));
  //     }
  // }, [taskObserver, boardRef, imgData.images]);
  // function changeBoardRef(e) {
  //     boardRef.current = e;
  // }

  // // // const handleScroll = (dataRbdDraggableId, isBottom) => {
  // const handleScroll = (dataRbdDraggableId, scrollHeight, scrollTop, outerHeight) => {
  //     if(scrollTop <= outerHeight) {
  //         console.log('load new data', [scrollTop, outerHeight])
  //     }
  //     // const index = dataRbdDraggableId.lastIndexOf("-");
  //     // console.log('ID', dataRbdDraggableId.substr(index + 1))

  //     // console.log('IsBottom', isBottom)
  //     console.log('dataRbdDraggableId', dataRbdDraggableId);
  //     console.log('scrollHeight', scrollHeight);
  //     console.log('scrollTop', scrollTop);
  //     console.log('outerHeight', outerHeight);
  // }

  // for IntersectionObserver !!! TEST
  const handleScroll = (entities, observer) => {
    //     const y = entities[0].boundingClientRect.top;
    // // console.log('observer', observer);
    //     if (state.prevY > y) {
    //     //   const lastUser = this.state.users[this.state.users.length - 1];
    //     //   const curPage = lastUser.id;
    //     //   this.getUsers(curPage);
    //     //   this.setState({ page: curPage });
    //     console.log('Y2', y)
    //     }
    //     console.log('Y', y)

    entities.forEach((entry) => {
      const cid = entry.target.parentNode.attributes[0].nodeValue;
      if (entry.isIntersecting) {
        const columns = state.columns;
        const col = columns.filter((c) => {
          return c.id == cid;
        })[0];
        retrieveNextItems(cid, col.current_page, col.last_page, col.hash, true);
      }
    });
  };
  // // ==============================================================================================================================
  // First render
  useEffect(() => {
    const params = queryString.parse(window.location.search);
    const org = params.organisation ?? params.org;
    const con = params.contractor ?? params.con;
    let baseurlINK = `${getBaseUrl()[0]}tasks-kanban`;
    let urlParams = '';
    if(org || con) {
      urlParams = '?';

      urlParams += objectToURLSearchParams({
        org,
        con
      });
      // if(org) {
      //   urlParams += `org=${org}`;
      //   if(con) urlParams += `&con=${con}`;
      // } else if(con) {
      //   urlParams += `con=${con}`;
      // }
    }

    // dispatch(filterTasks({
    //   org, con
    // }));

    if(org != null) {
      getOrgDetails(org);
    }

    baseurlINK += urlParams;
    setState({ ...state, record_type: state.record_type });
    setBaseUrlState(baseurlINK);
    getList();
    window.addEventListener("load", handleLoad);

    if (org != null || con != null) filter(org, con, "kanban");

    // check if params has task attribute
    if(params.task) {
      setTitle(params.task);
      setMetaDescription(params.description.replaceAll('%20', ' '));
    }
  }, []);

  // check if an organisation is selected
  useEffect(() => {
    const params = queryString.parse(props.location.search);
    const org = params.organisation ?? params.org;
    if(selectedOrg != org) {
      getOrgDetails(org);
    }
  });

  // Re-render if searching or selecting on subsidemenu
  useEffect(() => {
    // Advance Search
    console.log('props.taskAdvanceSearch: ', props.taskAdvanceSearch);
    if (props.taskAdvanceSearch.data) advanceSearch(props.taskAdvanceSearch);

    // Sidemenu Filter Subtasks
    // if (!props.taskSearchString) {
    //   if (props.taskFilter.org || props.taskFilter.con) filter();
    // }

    if (props.taskAdvanceSearch.length == 0) {
      // const searchParams = new URLSearchParams(window.location.search);
      // if(searchParams.size > 0) return;
      if (props.taskFilter.org || props.taskFilter.con) {
        filter();
      } else {
        setState(s => ({ ...s, loading: true }));
        console.log('getList()');
        getList();
      }
    } 


    // let urlParams = '';
    // if(props.taskFilter.org || props.taskFilter.con) {
    //   urlParams = '?';
    //   if(props.taskFilter.org) {
    //     urlParams += `org=${props.taskFilter.org}`;
    //     if(props.taskFilter.con) urlParams += `&con=${props.taskFilter.con}`;
    //   } else if(props.taskFilter.con) {
    //     urlParams += `con=${props.taskFilter.con}`;
    //   }

    //   history.push({
    //     pathname: props.location.pathname,
    //     search: urlParams
    //   });
    // }

  const {con, org} = props.taskFilter;

    let urlParams = '?' + objectToURLSearchParams({ con, org });
    history.push({
      pathname: props.location.pathname,
      search: urlParams
    });

  }, [props.taskFilter, props.taskAdvanceSearch]);
  
  const setOptions = ({coders, requestors, pricing_methods, statuses, request_types}) => {
    setOptionState({
      ...stateOptions,
      coders: coders,
      requestor: requestors,
      pricing_methods: pricing_methods,
      statuses: statuses,
      request_types: request_types,
    });
  }

  useEffect(() => {
    search();
  }, [props.taskSearchString]);

  const retrieveNextItems = (
    id = null,
    currentPage = null,
    lastPage = null,
    hash = null,
    isScroll = false
  ) => {
    if (currentPage === lastPage && !isScroll) {
      Swal.fire({
        title: "Error!",
        text: "All tasks in this column has already been displayed!",
        icon: "error",
        confirmButtonText: "Close",
      });
    } else {
      const params = {};
      params.project = state.selectedProject;
      params.record_type = recordType ?? "request";
      params.view = state.view;
      params.page = currentPage + 1;
      params.company_id = props.taskFilter.org;
      params.coder_id = props.taskFilter.con;
      if (hash && id && lastPage) {
        params.board = `${hash}:${id}:${lastPage}`;
      }

      Api.getRequestProjectTasks(params)
        .then((result) => {
          setOptions(result.data);
          const data = result.data.board;
          const columns = state.columns;

          Object.values(data).forEach((brd) => {
            const ck = Object.keys(columns).find(
              (key) => columns[key].id === brd.id
            );
            const temp = columns[ck];

            if (brd.rerender) {
              columns[ck] = brd;
            } else {
              columns[ck].cards = [...temp.cards, ...brd.cards];
              columns[ck].current_page = brd.current_page;
              columns[ck].last_page = brd.last_page;
              columns[ck].hash = brd.hash;
            }
          });

          return setState({
            ...state,
            loading: false,
            columns: columns,
            isAddStatusColumn: false,
            isUpdateStatusColumn: false,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getOrgDetails = (orgId) => {
    setSelectedOrg(orgId);
    if(['number', 'string'].indexOf(typeof orgId) < 0) console.log('orgId: ', orgId);;
    Api.getOrgDetails(orgId).then(result => {
        let cb = result.data.company_credits;
        let tb = result.data.task_balance;
        const name = result.data.company_name;

        //get the credit balance
        let num1 = parseFloat(tb);
        let num2 = parseFloat(cb);
        cb = (num2 - num1).toString();

        // format cb
        cb = cb.split('.');
        let hr = cb[0], min = `0.${cb[1]}`;
        cb = `${hr} hours ${(min * 60).toFixed(2)} mins`;

        // format tb
        tb = tb.split('.');
        hr = tb[0];
        min = `0.${tb[1]}`;
        tb = `${hr} hours ${(min * 60).toFixed(2)} mins`;

        setOrgDetails({name, creditBalance: cb, taskBalance: tb});
    }).catch(error => {
        console.log(error);
        setState(s => ({ ...s, loading: true }));
        getList();
    });
}

  // Filtering Data
  const advanceSearch = (data) => {
    setState({ ...state, loading: true });
    Api.advanceSearchTasks(data)
      .then((result) => {
        if (result.data.board.length > 0)
          return setState((prevState) => ({
            ...prevState,
            columns: result.data.board,
            loading: false,
          }));

        return setState((prevState) => ({
          ...prevState,
          columns: defaultColumns,
          loading: false,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Search Data
  const search = () => {
    setState({ ...state, loading: true });
    // Api.searchTasks([props.taskSearchString, state.displayType, props.taskFilter.org, props.taskFilter.con]).then(result => {
    if (props.taskSearchString != null && props.taskSearchString != "") {
      if(state.requestType === "timeline") {
        Api.getTasksForTimeline(0)
        .then((result) => {
          if(state.requestType === "timeline") {
            return setState((prevState) => ({
              ...prevState,
              events: result.data.events ?? [],
              assignments: result.data.assignments ?? [],
              loading: false,
            }));
          }
          if (result.data.board.length > 0) {
            return setState((prevState) => ({
              ...prevState,
              columns: result.data.board,
              loading: false,
            }));
          }
  
          return setState((prevState) => ({
            ...prevState,
            columns: defaultColumns,
            loading: false,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
      } else {
        Api.searchTasks([
          props.taskSearchString,
          state.record_type,
          state.view,
          props.taskFilter.org,
          props.taskFilter.con,
        ])
        .then((result) => {
          if(state.view === "timeline") {
            return setState((prevState) => ({
              ...prevState,
              events: result.data.events ?? [],
              assignments: result.data.assignments ?? [],
              loading: false,
            }));
          }
          if (result.data.board.length > 0) {
            return setState((prevState) => ({
              ...prevState,
              columns: result.data.board,
              loading: false,
            }));
          }
  
          return setState((prevState) => ({
            ...prevState,
            columns: defaultColumns,
            loading: false,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
      }
    }
  };

  const filter = () => {
    setState({ ...state, loading: true });
    // use kanban as default 2nd parameter, its value can be list
    Api.filterTasks([
      props.taskFilter["org"],
      props.taskFilter["con"],
      state.displayType,
      props.taskFilter["page"],
    ])
    .then((result) => {
      setOptions(result.data);

      if (result.data.board.length > 0)
        return setState((prevState) => ({
          ...prevState,
          columns: result.data.board,
          loading: false,
        }));

      return setState((prevState) => ({
        ...prevState,
        columns: defaultColumns,
        loading: false,
      }));
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const getBaseUrl = () => {
    return window.location.href.match(/^.*\//);
  };

  const popUpData = async (board) => {
    if (state.task_id) {
      Api.taskDetails(state.task_id)
        .then(async (result) => {
          document.body.classList.add("actual-time-and-estimate-show");

          let main = result.data.content.comments;
          let comments = [];

          if(main) {
            const comments_length = Object.keys(main).length;
            if (comments_length > 0) {
              comments = main.replies ?? [];
              delete main.replies;
              comments.push(main);
              console.log(comments);
              setComments(comments);
            }
          }

          return setState({
            ...state,
            id: state.task_id,
            newRequest: true,
            requestDetails: result.data.content,
            columns: board,
            loading: false,
            isAddStatusColumn: false,
            isUpdateStatusColumn: false,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return setState({
      ...state,
      loading: false,
      columns: board,
      isAddStatusColumn: false,
      isUpdateStatusColumn: false,
    });
  };

  const handleLoad = () => {
    document.onmouseup = function (e) {
      if (
        !e.target.closest(".actual-time-and-estimate") &&
        document.body.classList.contains("actual-time-show")
      ) {
        document.body.classList.remove("actual-time-show");
        document
          .querySelector(".timer-picker-div")
          .classList.add("toogle-hide");
      }

      if (
        !e.target.closest(".primary-assignee") &&
        document.body.classList.contains("primary-assignee-show")
      ) {
        document.body.classList.remove("primary-assignee-show");
        document
          .querySelector(".primary-assignee-content")
          .classList.add("toogle-hide");
      }

      if (
        !e.target.closest(".primary-qa-content") &&
        document.body.classList.contains("primary-qa-show")
      ) {
        document.body.classList.remove("primary-qa-show");
        document
          .querySelector(".primary-qa-content")
          .classList.add("toogle-hide");
      }
    };
  };

  const getList = () => {
    const params = {};
    params.project = state.selectedProject;
    params.record_type = recordType ?? "request";
    params.view = state.view;
    params.company_id = props.taskFilter.org;
    params.coder_id = props.taskFilter.con;

    Api.getRequestProjectTasks(params)
    .then((result) => {
      
      const searchParams = new URLSearchParams(window.location.search);
      if(searchParams.size > 0) return;
        setOptions(result.data);
        popUpData(result.data.board);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskAttributeInList = (board_id, task_id, attribute, value) => {
    const { columns } = state;
    let board = [];

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].id === board_id) {
        board = columns[i];
        break;
      }
    }

    const tasks = board.cards;
    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].id === task_id) {
        tasks[i].content[attribute] = value;
        break;
      }
    }
  };

  const updateKanbanColumns = () => {
    setState({
      ...state,
      isAddStatusColumn: false,
      isUpdateStatusColumn: false,
    });
    const params = {};
    params.project = state.selectedProject;
    params.record_type = state.record_type;
    params.view = state.view;
    params.company_id = props.taskFilter.org;
    params.coder_id = props.taskFilter.con;

    return Api.getRequestProjectTasks(params)
      .then((result) => {
        setOptions(result.data);
        popUpData(result.data.board);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addRequest = () => {
    setState({ ...state, isSubmit: true });
    Api.addTask({ isKanban: 1, type: state.record_type })
      .then((result) => {
        console.log(result);
        let columns = state.columns;
        columns[0].cards.splice(0, 0, result.data.task);
        setState({
          ...state,
          id: result.data.task.id,
          newRequest: true,
          columns: columns,
          requestDetails: result.data.task.content,
          isSubmit: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addStatusColumn = () => {
    setState({
      ...state,
      isAddStatusColumn: true,
      isUpdateStatusColumn: false,
    });
  };

  const updateStatusColumn = () => {
    setState({
      ...state,
      isAddStatusColumn: false,
      isUpdateStatusColumn: true,
    });
  };

  const requestDetails = async (e) => {
    if (title !== "Requests | Skynet V2") {
      setTitle("Requests | Skynet V2");
      setMetaDescription("Jiffi Web Help - CRM & Project Management");
    }

    let id = e.id;
    setState({ ...state, id: id, newRequest: true, requestDetails: e });

    if (id !== state.id) {
      console.log(e.id);
      Api.getComment(e.id)
        .then((result) => {
          const comments_length = Object.keys(result.data).length;
          if (comments_length > 0) {
            let main = result.data;
            let comments = main.replies;
            delete main.replies;
            comments.push(main);
            console.log(comments);
            setComments(comments);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    document.body.classList.add("actual-time-and-estimate-show");
  };

  const reloadData = (id = null, reload = false) => {
    Api.taskDetails(id)
      .then((result) => {
        let newDetails = result.data;
        setState({ ...state, requestDetails: newDetails.content });
        cardDetails(result.data, id, reload);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeRequestForm = () => {
    setState({ ...state, id: "", newRequest: false });
    setComments([]); // empty comments after closing the request form

    if (title !== "Requests | Skynet V2") {
      setTitle("Requests | Skynet V2");
      setMetaDescription("Jiffi Web Help - CRM & Project Management");
    }
  };

  const cardDetails = (e, id, reload = false) => {
    let columnsList = state.columns;
    console.log(columnsList);
    for (let index = 0; index < columnsList.length; index++) {
      let cards = columnsList[index]["cards"]; // get card's column
      for (let i = 0; i < cards.length; i++) {
        if (cards[i].id === id) {
          cards[i].content = e.content; // update card's content
          break; // stop loop if condition is met
        }
      }
    }

    setState({
      ...state,
      columns: columnsList,
      newRequest: !reload ? true : false,
    }); // update column state
  };

  // called when task's status is updated using the request form
  const updateBoard = (newColumn, oldColumnId, cardId) => {
    let columns = state.columns;

    // Get request current status group
    const columnIndex = columns.findIndex((obj) => obj.id === newColumn.value); // get column index where to move the card
    const oldColumnIndex = columns.findIndex((obj) => obj.id === oldColumnId); // get column index where to move the card

    // get the prev and new columns
    let old_column = columns[oldColumnIndex];
    let selected_column = columns[columnIndex];

    // get cards for prev and new columns
    let old_column_cards = old_column?.cards;
    let selected_column_cards = selected_column?.cards ?? [];

    // get card INDEX
    const objIndex = old_column_cards.findIndex((obj) => obj.id === cardId);
    let selected_card = null;

    if (objIndex >= 0) {
      selected_card = old_column_cards[objIndex]; // card's details using index
      old_column_cards.splice(objIndex, 1); // remove the selected card from the previous column
    }

    if (selected_card) {
      selected_card.content.status.id = newColumn.value; // update card's status id
      selected_card.content.status.title = newColumn.label; // update card's status title
      selected_column_cards.unshift(selected_card); // push selected card to the first array of selected column
    }

    if(old_column) {
      old_column.cards = old_column_cards ?? [];
    }

    if(selected_column) {
      selected_column.cards = selected_column_cards ?? [];
    }

    let updatedColumns = state.columns;
    updatedColumns[columnIndex] = selected_column;
    updatedColumns[oldColumnIndex] = old_column;

    setState({
      ...state,
      loading: false,
      columns: columns,
      isAddStatusColumn: false,
      isUpdateStatusColumn: false,
    });
  };

  // called when column is added or updated
  const updateBoardColumns = (e) => {
    let columnsOld = state.columns;
    console.log("columnsOld", columnsOld);
    let columns = state.columns;
    let requestDetails = state.requestDetails;

    // Get request current status group
    const columnIndex = columns.findIndex((obj) => obj.id === e.value); // get column index where to move the card
    let old_column = columns[requestDetails.status.id - 1].cards; // get the old column where the card is from
    let selected_column = columns[columnIndex].cards;

    // get request current status group
    let cards = columnsOld[requestDetails.status.id - 1].cards;

    // get card INDEX
    const objIndex = old_column.findIndex(
      (obj) => obj.id === parseInt(state.id)
    ); // get card's index
    let selected_card = old_column[objIndex]; // card's details using index

    selected_card.content.status.id = e.value; // update card's status id
    selected_card.content.status.title = e.label; // update card's status title
    old_column.splice(objIndex, 1); // remove the selected card from the previous column
    selected_column.unshift(selected_card); // push selected card to the first array of selected column
    console.log(columns); // check columns if the card is moved from the previous column to new column
  };

  const IsJsonString = (str) => {
    try {
      var json = JSON.parse(str);
      return typeof json === "object";
    } catch (e) {
      return false;
    }
  };

  const propertyDetails = (e) => {
    document.body.classList.remove("minimize-form");
    if (e.credentials) {
      let credentials = e.credentials;
      for (let index = 0; index < credentials.length; index++) {
        if (IsJsonString(credentials[index].details)) {
          credentials[index].details = JSON.parse(credentials[index].details);
        }
      }
      setState({
        ...state,
        propertyDetailsName: e.name,
        propertyDetails: credentials,
        isPdetails: true,
      });
    }
  };

  const updateDetails = (e) => {
    let statuses = state.columns;
    let temp = state.requestDetails;
    let tempTask = {
      id: e.id,
      content: e,
    };

    // check whether task status was updated
    if (e.new_status) {
      let columnId = temp.status.value;

      // add task to new board
      statuses.some(function (stat, i) {
        if (stat.id === e.status.value) {
          // add task
          stat.cards.splice(0, 0, tempTask);
          return true;
        }
      });

      // remove task from old board
      statuses.some(function (stat, i) {
        if (stat.id !== columnId) {
          // get index of task
          stat.cards.some(function (task, j) {
            if (task.id === e.id) {
              stat.cards.splice(j, 1);
              return true;
            }
          });
          // remove task from its current board
          return true;
        }
      });

      const columns = statuses;

      setState({ ...state, details: e, columns: columns });
    } else {
      setState({ ...state, details: e });
    }
  };

  const openPdetails = (event) => {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    setState({ ...state, isPdetails: true });
  };

  const closePdetails = (event) => {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    setState({ ...state, isPdetails: false });
  };

  const closeStatusForm = (event) => {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    setState({
      ...state,
      isAddStatusColumn: false,
      isUpdateStatusColumn: false,
    });
  };

  const minimize = (event) => {
    event.stopPropagation();
    document.body.classList.add("minimize-form");
  };

  const updateRequest = (data) => {
    // find section from selected request task
    const section = state.columns.find((item) => item.id == data.status.id);

    //Find index of specific object using findIndex method.
    let objIndex = section.cards.findIndex((obj) => obj.id == data.id);
    section.cards.splice(objIndex, 1);
    setState({ ...state, columns: state.columns });
  };

  const handleChangeTab = (type) => {
    setState((prevState) => ({
      ...prevState,
      requestType: type,
      loading: false,
    }));
  };

  const testGanttData = {
    data: [
      {
        id: 1,
        text: "Task #1",
        start_date: "15-03-2021",
        duration: 3,
        progress: 0.6,
      },
      {
        id: 206,
        text: "title",
        start_date: "25-03-2021",
        duration: 5.5,
        progress: 0,
      },
    ],
    links: [{ id: 1, source: 1, target: 2, type: "0" }],
  };

  const requestBoardBody = () => {
    return (
      <React.Fragment>
        {state.newRequest && (
          // !!!
          <RequestForm
            coders={stateOptions.coders}
            requestor={stateOptions.requestor}
            details={state.requestDetails}
            pricing_methods={stateOptions.pricing_methods}
            request_types={stateOptions.request_types}
            statuses={stateOptions.statuses}
            columns={state.columns}
            close={closeRequestForm}
            reloadData={reloadData}
            updateBoard={updateBoard}
            name={state.name}
            baseurlINK={stateBaseUrl}
            comments={comments}
            propertyDetails={propertyDetails}
            updateDetails={updateDetails}
            updateList={props.taskSearchString ? search : getList}
            updateTaskAttributeInList={updateTaskAttributeInList}
          />
        )}
        {state.loading ? (
          <LoadingDiv />
        ) : state.requestType == "list" ? (
          <RequestList rows={columns} requestDetails={requestDetails} />
        ) : state.requestType == "timeline" ? (
          <RequestTimeline
            events={state.events ?? []}
            coders={stateOptions.coders}
            assignments={state.assignments ?? []}
            state={state}
            requestForm={RequestForm}
            startDate={new Date(2022, 0, 1)}
            endDate={new Date(2022, 2, 1)}
          />
        ) : (
          // <RequestTimeline tasks={testGanttData} columns={columns} coders={stateOptions.coders} state={state} requestForm={RequestForm} project={state.selectedProject} recordType={state.record_type} />
          <ControlledBoard
            board={data}
            content={data.columns}
            columns={columns}
            coders={stateOptions.coders}
            updateRequest={updateRequest}
            requestDetails={requestDetails}
            updateBoard={updateKanbanColumns}
            retrieveNextItems={retrieveNextItems}
            handleScroll={handleScroll}
            userRole={loginDetails.role}
          />
        )}
      </React.Fragment>
    );
  };

  const requestBoardHeader = () => {
    return (
      <>
      <div className="row" style={{paddingLeft: "12px"}}>
        {
          orgDetails.name &&
          <>
            <div className="kanban-org-label" style={{marginRight: "46px"}}>Business: <span className="kanban-org-details">{orgDetails.name}</span></div>
            <div className="kanban-org-label">Credits Available: <span className="kanban-org-details">{orgDetails.creditBalance} (including {orgDetails.taskBalance} wip)</span></div>
          </>
        }
      </div>
      <div className="row">
        <div className="col-md-6">
          <button
            className={`btn btn-properties-tab ${
              state.requestType == "board" ? "active" : ""
            }`}
            onClick={() => handleChangeTab("board")}
          >
            Board
          </button>
          <button
            className={`btn btn-properties-tab ${
              state.requestType == "list" ? "active" : ""
            }`}
            onClick={() => handleChangeTab("list")}
          >
            List
          </button>
          <button
            className={`btn btn-properties-tab ${
              state.requestType == "timeline" ? "active" : ""
            }`}
            onClick={() => handleChangeTab("timeline")}
          >
            Timeline
          </button>
        </div>

        {state.requestType != "timeline" && (
          <div className="col-md-6 text-right">
            {loginDetails.role === 'super admin' &&
              <button
                onClick={addStatusColumn}
                className="btn btn-primary mr-4"
                disabled={state.isSubmit}
              >
                {state.isSubmit ? (
                  <i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i>
                ) : (
                  <i className="fa fa-plus"></i>
                )}
                Add Column
              </button>
            }

            <button
              onClick={addRequest}
              className="btn btn-primary"
              disabled={state.isSubmit}
            >
              {/* <button onClick={addRequest(state.requestType == 'board' ? 1 : 0)} className="btn btn-primary" disabled={state.isSubmit} > */}
              {state.isSubmit ? (
                <i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i>
              ) : (
                <i className="fa fa-plus"></i>
              )}
              Add Request
            </button>
          </div>
        )}
      </div>
      </>
    );
  };

  const columns = state.columns;
  const data = { columns };
  // let sampleData = state.sampleData;
  // let newTable = [];
  // for (let index = 0; index < sampleData.length; index++) {
  //     newTable.push(Object.values(sampleData[index]))
  // }

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={metaDescription}
        />
      </Helmet>

      {state.loading && <LoadingIndicator loading={state.loading} />}

      <div className="page-content">
        {/* REQUEST BOARD HEADER */}
        <div className="header-title" style={{ padding: "15px 15px 0 15px" }}>
          {requestBoardHeader()}
        </div>

        {/* REQUEST BOARD BODY (Board / List) */}
        <div className="container-fluid container-body">
          {requestBoardBody()}
        </div>
      </div>

      {state.isPdetails && (
        <PropertyDetails
          minimize={minimize}
          open={openPdetails}
          close={closePdetails}
          name={state.propertyDetailsName}
          data={state.propertyDetails}
        />
      )}

      {state.isAddStatusColumn && (
        <AddKanbanColumnForm
          updateBoard={updateKanbanColumns}
          isNew={true}
          close={closeStatusForm}
          recordType={state.record_type}
        />
      )}
    </React.Fragment>
  );
}

const mapStatetoProps = ({ Task }) => {
  return {
    taskAdvanceSearch: Task.taskAdvanceSearch,
    taskSearchString: Task.taskSearchString,
    taskFilter: Task.taskFilter,
  };
};

export default connect(mapStatetoProps)(TaskKanban);
